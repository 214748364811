import React from "react";
import styled from "styled-components";
import Row from "react-bootstrap/Row";
import {Col} from "react-bootstrap";
import Card from "../components/card";

const FeatureHeader = styled.h2`
    margin-bottom: 5%
  `;

const Features = (props) => {

  return (
      <React.Fragment>
        <Row>
          <Col>
            <FeatureHeader>What we can do for you</FeatureHeader>
          </Col>
        </Row>
        <Row>
          {prepareCards(props.cardsData)}
        </Row>
      </React.Fragment>
  );
}

function prepareCards(cardsData) {
  return (
      <React.Fragment>
        {cardsData.map(card =>
            <Col lg={4} md={6} sm={12} align="center">
              <Card
                  imgSrc={card.imgSrc}
                  imgAlt={card.headerText}
                  headerText={card.headerText}
                  paragraphText={card.paragraphText}
                  sectionReference={card.sectionReference}
                  buttonText={card.buttonText}
                  buttonVariant={card.buttonVariant}
                  buttonDisabled={card.buttonDisabled}
              />
            </Col>)}
      </React.Fragment>
  );
}

export default Features;