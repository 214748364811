import React from 'react'
import styled from 'styled-components';
import Button from "react-bootstrap/Button";
import scrollTo from 'gatsby-plugin-smoothscroll';
import {animated, useSpring} from 'react-spring'

const trans = (x, y, s) => `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`
const CardMain = styled.div`
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    height: 400px;
    width: 300px;
    margin: 10px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.3);
    transition: box-shadow 0.5s;
    will-change: transform;
    overflow: hidden;
    &:hover{ 
       box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.3);
    }    
  `;

const Img = styled.img`
    width: 64px;
    height: 64px;
  `;

const HeaderText = styled.h3`
  flex-basis: 10%;`;

const ParagraphText = styled.p`
   flex-basis: 20%;
  `;

const Card = (props) => {


  const [springProp, set] = useSpring(() => ({ xys: [1, 1, 1], config: { mass: 5, tension: 150, friction: 40 } }))

  return (
    <animated.div
        style={{ transform: springProp.xys.interpolate(trans) }}
      >
      <CardMain>
        <Img alt={props.imgAlt} src={props.imgSrc} />
        <HeaderText>
          {props.headerText}
        </HeaderText>
        <ParagraphText>
          {props.paragraphText}
        </ParagraphText>
        <Button
            onClick={() => scrollTo(props.sectionReference)}
            variant={props.buttonVariant}
            disabled={props.buttonDisabled}
        >
          {props.buttonText}
        </Button>
      </CardMain>
    </animated.div>
  );
}

export default Card;